import React, {Fragment, useEffect, useRef, useState} from 'react';



const PostTags = (props) => {
    var {terms,  termsToShow, setTermsToShow} = props;

    let prefix = 'fhrs--searchResult--body';

    let allTerms = [];
    

    if(terms && Object.keys(terms).length > 0){
    
        return (
            <ul className={`${prefix}--post__tags`}>
                { Object.keys(terms)

                    //building Term Obj
                    .reduce((carry, tax) => {

                        if(terms[tax] && terms[tax].length > 0){
                            terms[tax].forEach((item, index) => {
                                
                                if (!carry[index]) carry[index] = [];

                                carry[index].push({name:item, tax});
                            })
                        }
                        return carry;
                    }, [])
                
                    //Creating array of terms
                    .reduce((carry, arr) => {
                        allTerms = carry.concat(arr);
                        return allTerms;
                    }, [])
                
                    // Limit amount showing
                    .filter((item, index) => index < termsToShow)
                
                    .map(termObj =>  {

                        if(termObj.name !== undefined){
                            return (
                                <li key={`${termObj.tax}-term--${termObj.name}`} className={`${prefix}--post__tags--item ${termObj.tax}`}>
                                    <span>
                                        {termObj.name.replace(/\-/gi, ' ').replace(/\_/gi, ' ').decodeHTML()}
                                    </span>
                                </li>
                            )
                        }

                    })
                }

                {allTerms.length > termsToShow 
                    && <li 
                        className={`${prefix}--post__tags--more`}
                        onClick={(e) => {
                            e.preventDefault();
                            setTermsToShow(10);
                        }}
                    >
                        {`+ ${allTerms.length - 3} more`}
                    </li> 
                }
            </ul>
        )
    }

    return null;
}

export default PostTags;

