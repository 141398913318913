import React, {useEffect} from 'react';
import { connectPagination } from 'react-instantsearch-dom';



const CustomPagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
}) => {


	const setNewURLParams =(urlParams) => {
		urlParams.set('paged', currentRefinement);
		history.replaceState(null, null, `?${urlParams.toString()}`);
	}

	useEffect(() => {
	    const urlParams = new URLSearchParams(window.location.search);
	    setNewURLParams(urlParams);
	}, [currentRefinement]);

	return (
		<div className="fhrs--pagination"></div>
	);
}

export default connectPagination(CustomPagination);


			// {currentRefinement > 1 && <button className="fhrs--pagination--button prev" onClick={(e) => goToPage(e, currentRefinement-1)}>{ArrowLeft} Previous</button>}
			// {currentRefinement < nbPages && <button className="fhrs--pagination--button next" onClick={(e) => goToPage(e, currentRefinement+1)}>Next {ArrowRight}</button>}