import React, {useState, useRef, useEffect} from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ isSearchStalled, currentRefinement, refine }) => {

	const searchInput = useRef();
	const [currSearch, updateCurrSearch] = useState(currentRefinement);
	const [currSearchID, updateCurrSearchID] = useState();


	const setNewURLParams =(urlParams) => {
		urlParams.set('search', currentRefinement);
		history.replaceState(null, null, `?${urlParams.toString()}`);
	}

	useEffect(() => {
	    const urlParams = new URLSearchParams(window.location.search);
	    setNewURLParams(urlParams);
	}, [currentRefinement]);

	return (
		<div className="ais-SearchBox">
			<input
                label="Site Search Input"
				ref={searchInput}
			  	type="search"
			  	value={currSearch}
			  	placeholder="Search Keywords, Names, Etc..."
			  	onChange={(typeEvent) => {
			  		window.clearTimeout(currSearchID);
			  		updateCurrSearch(typeEvent.currentTarget.value);

			  		updateCurrSearchID(window.setTimeout(() => {
				  		refine(searchInput.current.value);
			  		}, 1000));
			  	}}
			/>
		</div>
	);
}

export default connectSearchBox(SearchBox);