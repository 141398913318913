import SearchBox from './CustomSearch';
import Stats from './CustomStats';
import RefinementList from './CustomRefinementList';
import Pagination from './CustomPagination';
import Hits from './CustomHits';

import Card from './Card';

const components = {
	Card, SearchBox, RefinementList, Stats, Pagination, Hits
};

export default components;