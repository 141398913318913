import React, {useState} from 'react';
import { decodeEntities } from '../helpers';
import PostTags from './PostTags';
import {get as _get} from 'lodash'

const Card = (props) => {

	const { layoutOptions, customOptions } = props;
	var { type, url, img, title, excerpt, date, _highlightResult, _snippetResult, items} = props.hit;
    excerpt = excerpt ? excerpt :  ''; 

    const [termsToShow, setTermsToShow] = useState(3);
   
    if(customOptions && customOptions.length > 0){
        var keys = Object.keys(customOptions[0]);

        keys.forEach(key => {
            var customOptionArray = customOptions.map(optionArray => optionArray[key]);
            layoutOptions[key] = customOptionArray.some((el) => el === true)
        });
    }

    var { show_arrow, show_date, show_image} = layoutOptions;

    if(!_highlightResult) return null;

    if(_highlightResult.excerpt && _highlightResult.excerpt.matchLevel !== 'none') {
    	
        excerpt = _get(_highlightResult, 'excerpt.value');

    } else if(excerpt !== '') {

	    excerpt = _get(_snippetResult, 'content.value', excerpt).trim() + '...';

	    // Adding Ellipsis to the beginning of snippeted content, where appropriate
	    if(excerpt[0].match(/[a-z]/)){
	    	excerpt = '...' + excerpt;
	    }
    }

	switch(type){
		case 'no-result':
			return (
				<div className="fhrs--no-results">
					<span className="fhrs--no-results--head">{decodeEntities(title)}</span>
					<span className="fhrs--no-results--body">{decodeEntities(excerpt)}</span>
				</div>
			);
			break;
		case 'event':
		case 'fhevent':
			var slug = url.split('/').filter(path => path !== "" && path !== "event");
			url = `/calendar/${slug}`;
			break;
	}

    title = _highlightResult.title ? _highlightResult.title.value  : title;

	return (
		<a href={`${url}`} className={`fhrs--searchResult ${type}`}>
			{img && show_image && <div className="fhrs--searchResult--image" style={{ backgroundImage: `url('${img}')`}} />}

			<div className="fhrs--searchResult--body">
				<h2 dangerouslySetInnerHTML={{__html: title}}></h2>
				<p dangerouslySetInnerHTML={{__html: excerpt}}></p>
				
                {show_date && <span className="fhrs--searchResult--body--footer-date">{date}</span>}

                {items && items
                // Only get items with thumbnails
                .filter(item => item.thumbnail)
                // Only get first 4
                .slice(0, 4)
                // Show thumbnails
                .map(item => {
                    return (
                        <img className="content-image" src={`${item.thumbnail}`} />
                    );
                })}
	           
	            <PostTags 
	            	terms={Object.keys(props.hit).reduce((carry, key) => {
	            		if(layoutOptions.taxes_to_show && Object.keys(layoutOptions.taxes_to_show).includes(key)){

                            // Ensuring Array
	            			let propTerms = props.hit[key] instanceof Array ? props.hit[key] : [props.hit[key]];

                            propTerms =  propTerms.map(termName => {
                            
                                // Find option apply to current item
                                var termOption = layoutOptions.taxes_to_show[key].terms.find(term => {
                                    return term.slug === termName.decodeHTML();
                                })

                                // Find correct name
                                if(termOption){
                                    if(termOption.card_display_name && termOption.card_display_name !== ''){
                                        return termOption.card_display_name.decodeHTML();
                                    } 

                                    if(termOption.display_name && termOption.display_name !== ''){
                                        return termOption.display_name.decodeHTML();
                                    }

                                    return termOption.slug.decodeHTML(); 
                                }
                            });
                            
                            carry[key] = propTerms;

	            			return carry;
	            		}
	            		return carry;
	            	}, {})} 
	            	termsToShow={termsToShow} 
	            	setTermsToShow={setTermsToShow} 
	            />
			</div>

			{show_arrow && <span className="fhrs--searchResult--arrow">White Arrow Right</span>}

		</a>
	);
}

export default Card;


