import React from 'react';
import { connectStats } from 'react-instantsearch-dom';

const Stats = ({  nbHits }) => (
  <span className="ais-Stats">
  	Showing {nbHits} results
  </span>
);

export default connectStats(Stats);
