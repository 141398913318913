import React from 'react';
import {render} from 'react-dom';
import algoliasearch from 'algoliasearch/lite';
import 'url-search-params-polyfill';
import { 
    InstantSearch, 
    Index, 
    CurrentRefinements, 
    Panel,
    Configure,
    ClearRefinements
} from 'react-instantsearch-dom';


import Components from './components';
const {Card, Hits, SearchBox, RefinementList, Stats, Pagination} = Components;

const App = (props) => {

    const jsonOptions = JSON.parse(props.options);

    console.log(jsonOptions);

    const fh_algolia_admin = {
        application_id : jsonOptions.application_id, 
        api_key : jsonOptions.api_key, 
        readonly_api_key : jsonOptions.readonly_api_key
    }

    let fh_algolia_filters = jsonOptions['fh_algolia_facets_to_filter_by'];

    let fh_algolia_custom_option_facets = jsonOptions['fh_algolia_facets_with_custom_options'];

    var fh_algolia_template = {
        "multisite": jsonOptions['fh_algolia_multisite_config'],
        "search-bar": jsonOptions['search-bar'],
        "no-results": jsonOptions['no-results'], 
        "result": jsonOptions['result'],
        "sidebar": jsonOptions['sidebar'],
        "filters" : fh_algolia_filters ? fh_algolia_filters.map(filterName => {
            filterName = filterName.facet;
            return ({
                'facet' : filterName,
                'show_on_card' : jsonOptions[`fh_algolia_${filterName}_options`][`${filterName}_show_on_card`],
                "title" : jsonOptions[`fh_algolia_${filterName}_options`][`${filterName}_title`],
                "terms": jsonOptions[`fh_algolia_${filterName}_options`][`${filterName}_terms`].map(term => ({
                    "slug" : term[`${filterName}_slug`],
                    "display_name" : term[`${filterName}_display_name`],
                    "card_display_name" : term[`card_${filterName}_display_name`],
                }))
            })
        }) : [],
        "custom_options": fh_algolia_custom_option_facets ? fh_algolia_custom_option_facets.map(customOptionFacet => {
            customOptionFacet = customOptionFacet.custom_option_facet;

            return ({
                "facet" : customOptionFacet,
                "terms" : jsonOptions[`fh_algolia_${customOptionFacet}_custom_options`]
            })
        }) : []
    }

    fh_algolia_template.result.taxes_to_show = fh_algolia_template.filters.reduce((carry, item) => {
        if(item.show_on_card){ 
            carry[item.facet] = item; 
        }
        return carry;
    }, {});

    const searchClient = algoliasearch(fh_algolia_admin.application_id, fh_algolia_admin.readonly_api_key);
    const {sidebar, filters, custom_options} = fh_algolia_template;


    var urlParams = new URLSearchParams(window.location.search);

    console.log(filters);

    return (
        <InstantSearch
         indexName="wp_all" searchClient={searchClient}>

            <Configure
                hitsPerPage={+fh_algolia_template['search-bar']['results-per-page']} 
                distinct 
                filters={'status:publish'} 
                attributesToSnippet={['content:55']}
            />

            <div className="fhrs--head">
                <SearchBox defaultRefinement={urlParams.get('search') ? urlParams.get('search') : ''} />
                {fh_algolia_template['search-bar']['show-results-count'] && <Stats />}
            </div>

            <div className="fhrs--body">
                <Hits layoutOptions={fh_algolia_template} hitComponent={Card} />
            </div>

            {filters && filters.length > 0 &&
                <Panel className="fhrs--sidebar">
                    <h2>{sidebar.sidebar_heading ? sidebar.sidebar_heading : 'Filter By'}</h2>

                    <div className="fhrs--sidebar--int">
                        {filters.map(filter => {
                            return (
                                <RefinementList
                                    layoutOptions={sidebar}
                                    defaultRefinement={urlParams.get(filter.title) ? urlParams.get(filter.title) : []}
                                    title={filter.title}
                                    permItems={filter.terms} 
                                    attribute={filter.facet} 
                                />
                            );
                        })}

                        {sidebar['show-clear-filters'] && <ClearRefinements />}
                    </div>
                </Panel>
            }

        </InstantSearch>
    );
}

if( document.getElementById( 'fhrs--app' ) !== null ) {
	var insTarget = document.getElementById( 'fhrs--app' );

    render(
        <App {...insTarget.dataset} />,
        document.getElementById( 'fhrs--app' )
    )
}