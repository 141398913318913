import React, {Fragment, useEffect, useRef, useState} from 'react';
import { connectHits, connectInfiniteHits } from 'react-instantsearch-dom';
import Card from './Card';

import debounce from 'lodash/debounce';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import { find } from 'lodash';


const InfiniteHits = ({ 
	hits,
	hasPrevious,
	hasMore,
	refinePrevious,
	refineNext,
	layoutOptions
}) => {

  	const sentinel = useRef(0);

  	const listener = () => {
    	let rect = sentinel.current.getBoundingClientRect();
    	if ((window.innerHeight - rect.bottom > 0) && hasMore){
    		refineNext();
    	}
  	};

  	const delay = 100;

  	useEffect(() => {
		window.removeEventListener('scroll', listener)
    	document.addEventListener('scroll', debounce(listener, delay));
    	return () => window.removeEventListener('scroll', listener);
  	}, [hasMore]);


    function selectCustomOptions(custom_options, hit){

        var hit_keys = Object.keys(hit);

        return custom_options.reduce((accumulator, optionObj, index) => {


            var facet = hit_keys.find(key => key === optionObj.facet);


            if(!facet){
                return accumulator;
            } else {
                accumulator.push(optionObj.terms[`${hit[facet]}_custom_options`]);
                return accumulator;
            }
        }, []);
    }



	return (
		<Fragment>
			<TransitionGroup className="fhrs--results-list">
		    	{hits.map((hit, i) => {

                    var custom_options = selectCustomOptions(layoutOptions.custom_options, hit);

		    		return (
			            <CSSTransition
			              	key={`csstrans_${hit.objectID}_${i}`}
			              	timeout={1000}
			              	classNames="result"
			              	onEntering={(node) => {
			              		node.style.maxHeight = `${node.scrollHeight}px`;
			              	}}
			              	onEntered={(node) => {
			              		node.style.maxHeight = `none`;
			              	}}
			              	onExit={(node) => {
			              		node.style.maxHeight = `${node.scrollHeight}px`;
			              	}}
			              	onExiting={(node) => {
			              		node.style.maxHeight = `0px`;
			              	}}
			            >
				     		<li key={`li_${hit.objectID}_${i}`}>
				      			<Card layoutOptions={layoutOptions.result} customOptions={custom_options} filterOptions={layoutOptions.filters} hit={hit} />
				      		</li>
			      		</CSSTransition>
		    		)
		    	})}

			    {
			    	/* -------  No Results ------------ */
			    	hits.length === 0 && 
			            <CSSTransition
			              	key={`csstrans_no_result`}
			              	timeout={1000}
			              	classNames="result"
			              	onEntering={(node) => {
			              		node.style.maxHeight = `${node.scrollHeight}px`;
			              	}}
			              	onEntered={(node) => {
			              		node.style.maxHeight = `none`;
			              	}}
			              	onExit={(node) => {
			              		node.style.maxHeight = `${node.scrollHeight}px`;
			              	}}
			              	onExiting={(node) => {
			              		node.style.maxHeight = `0px`;
			              	}}
			            >
				     		<li key={`li_no_result`}>
				      			<Card 
									layoutOptions={layoutOptions}
					      			hit={{
					      				"type" : "no-result",
									    "title": layoutOptions['no-results']['no_results_heading'],
									    "excerpt": layoutOptions['no-results']['no_results_body'],
					      			}} 
					      		/>
				      		</li>
			      		</CSSTransition>

			    }



		  	</TransitionGroup>
			<div ref={sentinel} className="sentinel"></div>
	  	</Fragment>
  	);
}





export default connectInfiniteHits(InfiniteHits);