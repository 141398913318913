import React, {Fragment, useEffect} from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import { formatTypeTitle } from '../helpers.js';

const CustomRefinementList = ({
	items,
	currentRefinement,
	refine,
	isFromSearch,
	searchForItems,
	createURL,
	permItems,
	title,
	attribute,
	layoutOptions
}) => {

	const setNewURLParams =(urlParams) => {
		
		if(currentRefinement.length > 0){
			urlParams.set(title, currentRefinement.join(','));
		} else {
			urlParams.delete(title);
		}

		history.replaceState(null, null, `?${urlParams.toString()}`);
	}

	useEffect(() => {
	    const urlParams = new URLSearchParams(window.location.search);
	    setNewURLParams(urlParams);
	}, [currentRefinement]);

	return (
		<div className={`fhrs--refinement-list fhrs--refinement-list--${attribute}`}>
			{layoutOptions['show-select-all'] && 
				<button
					disabled={items.filter(item => item.label !== 'page').length === 0}
					className="fhrs--sidebar--int--toggle-all"
					onClick={(e) => {
						e.preventDefault();
						refine(currentRefinement.length === 0 ? permItems.map(item => item.slug) : []);
					}}
				>
					{currentRefinement.length === 0 ? 'Select All' : 'Deselect All'}
				</button>
			}

			<h3>{title}</h3>

			<ul>
				{permItems.map(item => {
					const algoliaItem = items.find(algoliaItem => algoliaItem.slug === item) ? items.find(algoliaItem => algoliaItem.slug === item) : false;

					item.display_name = item.display_name && item.display_name !== '' ? item.display_name : item.slug;

					return (
						<li key={item.slug}>
							<button 
								className={ `${currentRefinement.includes(item.slug) ? 'isActive' : ''}` }
								onClick={(e) => {
									e.preventDefault();
									refine((currentRefinement.includes(algoliaItem.value) || currentRefinement.includes(item.slug)) ? currentRefinement.filter(value => value !== item.slug) : currentRefinement.concat(item.slug));
								}} 
							>
								<label>{formatTypeTitle(item.display_name)}</label>
							</button>
						</li>
					)
				})}
			</ul>
		</div>
	);
}

//{algoliaItem && ` (${algoliaItem.count})`}
export default connectRefinementList(CustomRefinementList);
